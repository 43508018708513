export default {
  data () {
      return {
          createOrUpdate: ["create", "update"], // 当前是新增或者更新，弹窗分类
      }
  },
  methods: {
    // 解析二维码中的字符串
    parsingQRCode (str) {
      if (!str) {
        return false
      }
      // 格式：B249020BC4?{"bar_code":"1111111111","model":"2222222222"}
      let temp = {
        scanCode: ""
      }
      let markIndex = str.indexOf("?")
      if (markIndex === -1) {
        temp.scanCode = str
      } else {
        // 解析参数
        temp.scanCode = str.substring(0, markIndex)
        let params = str.substring(markIndex + 1)
        try {
          params = JSON.parse(params)
          temp = {
            ...temp,
            ...params
          }
        } catch (e) {
          temp.scanCode = str.substring(0, markIndex)
          // 参数解析失败
          console.log(e)
        }
      }
      // console.log(temp)
      return temp
    },
    sortForAssetOrPrincipal(items, companyId, categoryName, isFilter) {
      // 使用者、负责人根据条件排序
      // 有companyId事业部的排前面
      // 有categoryName分类的排前面
      // isFilter为true表示只显示筛选的，否则显示所有数据并排序
      let datas = JSON.parse(JSON.stringify(items))
      if (isFilter) {
        if (companyId && categoryName) {
          datas = datas.filter(item => item.company && item.company.id == companyId && item.category && item.category.name == categoryName)
        } else if (companyId) {
          // 按分类排序
          datas = datas.filter(item => item.company && item.company.id == companyId)
        } else if (categoryName) {
          // 按事业部排序
          datas = datas.filter(item => item.category && item.category.name == categoryName)
        }
      }
      try {
        if (companyId && categoryName) {
          // 1. companyId和categoryName都有值
          datas.sort((a, b) => {
            // 事业部相同，且等于companyId，按分类排序
            if (a.company.id == companyId && b.company.id == companyId) {
              // 两个类型都相同，且等于categoryName，就按照name升序排列
              if (a.category.name == categoryName && b.category.name == categoryName) {
                return a.name.localeCompare(b.name)
              }
              if (a.category.name == categoryName) {
                return -1
              }
              if (b.category.name == categoryName) {
                return 1
              }
              if (a.category.name == b.category.name) {
                return a.name.localeCompare(b.name)
              }
              return a.category.name.localeCompare(b.category.name) || a.name.localeCompare(b.name)
            }
            if (a.company.id == companyId) {
              return -1
            }
            if (b.company.id == companyId) {
              return 1
            }
            if (a.company.id == b.company.id) {
              return a.category.name.localeCompare(b.category.name) || a.name.localeCompare(b.name)
            }
            if (a.category.name == categoryName) {
              return -1
            }
            if (b.category.name == categoryName) {
              return 1
            }
            if (a.category.name == b.category.name) {
              return a.name.localeCompare(b.name)
            }
            return a.company.id.localeCompare(b.company.id) || a.category.name.localeCompare(b.category.name) || a.name.localeCompare(b.name)
          })
        } else if (companyId) {
          // 2. 只传递了companyId值
          datas.sort((a, b) => {
            if (a.company.id === companyId && b.company.id === companyId) {
              return a.category.name.localeCompare(b.category.name) || a.name.localeCompare(b.name)
            }
            if (a.company.id == companyId) {
              return -1
            }
            if (b.company.id == companyId) {
              return 1
            }
            if (a.company.id === b.company.id) {
              return a.category.name.localeCompare(b.category.name) || a.name.localeCompare(b.name)
            }
            return a.company.id.localeCompare(b.company.id) || a.category.name.localeCompare(b.category.name) || a.name.localeCompare(b.name)
          })
        } else if (categoryName) {
          // 3. 只传递了categoryName值
          datas.sort((a, b) => {
            if (a.category.name === categoryName && b.category.name === categoryName) {
              return a.name.localeCompare(b.name)
            }
            if (a.category.name === categoryName) {
              return -1
            }
            if (b.category.name === categoryName) {
              return 1
            }
            if (a.category.name === b.category.name) {
              return a.name.localeCompare(b.name)
            }
            return a.company.id.localeCompare(b.company.id) || a.category.name.localeCompare(b.category.name) || a.name.localeCompare(b.name)
          })
        } else {
          // 4. 其余按照name升序排列
          datas.sort((a, b) => a.name.localeCompare(b.name))
        }
      } catch (e) {
        console.log("排序异常：可能没有company.id或category.name。")
      }
      console.log(datas)
      return datas
    }
  }
}


